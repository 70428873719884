import $ from "jquery"
import jQuery from "jquery"

//Init pageloader
function initPageLoader() {
  var pageloaderTimeout = setTimeout(function () {
    $(".pageloader").removeClass("is-active")
    $(".infraloader").removeClass("is-active")
    clearTimeout(pageloaderTimeout)
  }, 500)
}

function initNavbar() {
  //Navbar fade
  if ($(".navbar-wrapper.navbar-fade.navbar-light").length) {
    // $(".navbar-wrapper.navbar-fade").wrap(
    //   '<div class="navbar-placeholder"></div>'
    // )
    // $(".navbar-placeholder").height(
    //   jQuery(".navbar-wrapper.navbar-fade").outerHeight()
    // )
    $(window).on("scroll", function () {
      var height = $(window).scrollTop()
      if (height > 65) {
        $(".navbar-wrapper.navbar-fade.is-transparent")
          .removeClass("is-transparent navbar-light")
          .addClass("navbar-faded")
      } else {
        $(".navbar-wrapper")
          .removeClass("navbar-faded")
          .addClass("is-transparent navbar-light")
      }
    })
  }

  //Navbar fade
  if ($(".navbar-wrapper.navbar-fade.navbar-default").length) {
    // $(".navbar-wrapper.navbar-fade").wrap(
    //   '<div class="navbar-placeholder"></div>'
    // )
    // $(".navbar-placeholder").height(
    //   jQuery(".navbar-wrapper.navbar-fade").outerHeight()
    // )
    $(window).on("scroll", function () {
      var height = $(window).scrollTop()
      if (height > 65) {
        $(".navbar-wrapper.navbar-fade.is-transparent")
          .removeClass("is-transparent")
          .addClass("navbar-faded")
      } else {
        $(".navbar-wrapper")
          .removeClass("navbar-faded")
          .addClass("is-transparent")
      }
    })
  }

  //Navbar Clone
  if ($(".is-cloned").length) {
    $(window).scroll(function () {
      var height = $(window).scrollTop()
      if (height > 50) {
        $(".is-cloned").addClass("is-active")
      } else {
        $(".is-cloned").removeClass("is-active")
      }
    })
  }

  //Toggle the sign up button color when solid navbar comes in
  if ($(".navbar-light").length) {
    $(window).on("scroll", function () {
      var height = $(window).scrollTop()
      if (height > 80) {
        $(".button-signup").removeClass("light-btn").addClass("secondary-btn")
      } else {
        $(".button-signup").removeClass("secondary-btn").addClass("light-btn")
      }
    })
  }
} //Init mobile menu

function initMobileMenu() {
  $(".custom-burger").on("click", function () {
    $(this).toggleClass("is-active")
    if ($(".navbar-menu").hasClass("is-active")) {
      $(".navbar-menu").removeClass("is-active")
      $(".navbar-fade.navbar-light").removeClass("is-dark-mobile")
    } else {
      $(".navbar-menu").addClass("is-active")
      $(".navbar-fade.navbar-light").addClass("is-dark-mobile")
    } //Revert navbar to initial color state

    if ($(".navbar-faded").hasClass("is-dark-mobile")) {
      $(".navbar-faded").removeClass("is-dark-mobile")
    }

    $(".navbar.is-static").toggleClass("is-dark-mobile")
  })
  $(".custom-burger").on("click", function () {
    $(this).find(".icon-box-toggle").toggleClass("active")
  })
  $(window).scroll(function () {
    var height = $(window).scrollTop()

    if ($(".navbar-menu").hasClass("is-active")) {
      if (height > 50) {
        $(".navbar-light").removeClass("is-dark-mobile")
        $(".dropdown-content").removeClass("dropdown-content-dark")
      } else {
        $(".navbar-light").addClass("is-dark-mobile")
        $(".dropdown-content").addClass("dropdown-content-dark")
      }
    }
  })
} //Init sidebar

export default function InitScript() {
  $(document).ready(() => {
    setTimeout(function () {
      initPageLoader()
      initNavbar()
      initMobileMenu()
    }, 1200)
  })
}
